@import "~rsuite/lib/styles/themes/default/index.less"; //The default style file.
@import "custom-theme.less"; // Style customization.

.side-navigation {
  background: #021b3b;
  color: #ffffff;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;

  .rs-sidenav-collapse-in {
    .rs-dropdown-item-content a,
    .rs-dropdown-item-content {
      color: #fff;
      text-decoration: none;
    }
    .rs-dropdown-item.selected {
      background: #0d2956;
    }
  }
}

.sideBar-hover-menu {
  .rs-dropdown-item:hover {
    background: #f4f4f4;
  }
}

.error {
  border: solid 1px red;
}

.selected {
  .rs-nav-item-content,
  .rs-nav-item-content:hover,
  .rs-dropdown-toggle,
  .rs-dropdown-toggle:hover {
    box-shadow: none;
    text-decoration: none;
    background: linear-gradient(0deg, #ff0078 0%, #ff311b 100%) !important;
  }
}

.selected-sub {
  .rs-nav-item-content,
  .rs-nav-item-content:hover,
  .rs-dropdown-toggle,
  .rs-dropdown-toggle:hover {
    box-shadow: none;
    text-decoration: underline;
  }
}

.selectedTab {
  .rs-nav-item-content,
  .rs-nav-item-content:hover {
    box-shadow: none;
    text-decoration: none;
    background: linear-gradient(0deg, #013066 0%, #37aee0 100%);
    color: #fff;
    font-size: 12pt;
  }
}

.rs-picker-disabled {
  opacity: 1;
}

.rs-input-group-disabled .rs-input {
  color: black;
}
//.rs-picker-disabled {
//  color: black !important;
//}

a:hover {
  text-decoration: none;
}

.rs-nav.rs-nav-vertical .rs-dropdown > .rs-dropdown-toggle {
  z-index: 9999999;
}

.content-container {
  -webkit-transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -o-transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: all 400ms cubic-bezier(0.25, 0.1, 0.25, 1); /* ease (default) */

  .top-navbar {
    padding: 12px;
    background: #f4f4f4;
  }

  .rs-content {
    margin: 12px;
    padding: 7px;
    z-index: 0;
  }
}

.navbar-brand {
  padding: 18px 20px;
  display: inline-block;
}

.content-card {
  .rs-content {
    background: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 15px;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0 0 10px 0;
      font-weight: normal;
    }
  }
}

.content-card.primary {
  .rs-content {
    background: #ff0078;
    background: linear-gradient(0deg, #ff0078 0%, #ff311b 100%);
    color: #f3f2ed;
  }
}
.content-card.primary-inverse {
  .rs-content {
    background: #ff0078;
    background: linear-gradient(0deg, #ff311b 0%, #ff0078 100%);
    color: #f3f2ed;
  }
}

.content-card.primary-inverse,
.content-card.primary {
  .rs-list {
    border: none;
    box-shadow: none;
  }
}

.alerts-table-item,
.alerts-table-no-alerts,
.alerts-table-cta {
  background: transparent;
  border: none;
  box-shadow: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.alerts-table-item:hover {
  cursor: pointer;
}
.alerts-table-cta {
  text-align: center;
  .rs-btn-link,
  .rs-btn-link:hover {
    color: inherit;
  }
}

.stat-card {
  text-align: center;
  .rs-content {
    padding: 15px 0;
    background: #021b3b;
    color: #d4d4d4;
    h2 {
      margin: 0;
      padding: 5px 0;
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 24px;
      color: #fff;
      font-weight: normal;
      line-height: normal;
    }
  }
}

.overview-stat-card {
  text-align: center;
  .rs-content {
    padding: 7px 0;
    background: #021b3b;
    color: #d4d4d4;
    h2 {
      margin: 0;
      padding: 5px 0;
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
    }
    h1 {
      margin: 0;
      padding: 0;
      font-size: 24px;
      color: #fff;
      font-weight: normal;
      line-height: normal;
    }
  }
}

.chart {
  .rs-content {
    height: 360px;
  }
}

.top-navbar {
  .rs-navbar-body {
    ul {
      margin-left: 25px;
    }
    ul > * {
      padding-left: 5px;
    }
  }
}

.buyer-sidebar {
  & > * {
    padding: 0;
  }
}
.buyer-sidebar-overlay {
  z-index: 999999;
}

.bar-progress {
  div {
    background: #facc68 !important;
    //background: #e28c4f !important;
  }
}

.summary-table {
  margin-bottom: 25px;
  th,
  td {
    padding: 5px;
    font-size: 12px;
  }

  th.right,
  td.right {
    text-align: right;
    padding-right: 8px;
  }

  &-callout {
    background: #f2f2f2;
  }

  thead {
    tr {
      //background: #d8dbe3;
    }
    td,
    th {
      font-size: 0.7rem;
      text-align: left;
      padding: 10px 5px;
    }
  }

  tbody {
    tr:hover {
      background: #d4d4d4 !important;
      cursor: pointer;
      td {
        background: #d4d4d4 !important;
      }
    }
  }

  tfoot {
    tr {
      td {
        /*background: $border-color-base;*/
        text-align: center;
      }
    }
  }

  &-primary {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }

  &-graph {
    min-width: 100px;
  }
}

.buyer-overview {
  &-content {
    width: 100%;
  }

  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;
  }
}

.clickable-data:hover {
  cursor: pointer;
}

.striped-rows:nth-child(even) {
  background-color: #eaeaea;
  .rs-table-cell {
    background-color: #eaeaea;
  }
}

.sources-list-superscript {
  vertical-align: super;
  font-size: 0.65em;
  background-color: #853535;
  color: white;
  border-radius: 20px;
  padding: 0px 3px;
  left: 19px;
  top: 1px;
  z-index: 1000;
  position: absolute;
}
.sources-list-row .rs-table-cell-content {
  padding: 5px;
}

/**
rsuite additional styles
 */
// table
.link-group .rs-table-cell-content,
.editor-cell .rs-table-cell-content {
  padding: 5px;
}

.pre-editor-cell .rs-table-cell-content {
  padding-left: 17px;
}

.adm-rs-table-cell-header-summary-wrapper {
  display: inline-block;
}
.adm-rs-table-cell-header-summary-content {
  font-size: 14px;
  color: #2eabdf;
  position: relative;
  //right: -16px;
}

.adm-rs-table-cell-header-summary-wrapper + .rs-table-cell-header-sort-wrapper,
.rs-table-column-group-cell-content + .rs-table-cell-header-sort-wrapper {
  position: relative;
  top: -25px;
}

.adm-rs-tree-cell-parent-date {
  text-align: right;
}

.rs-table-row-header {
  color: #636367;
  font-weight: bold;
}

.rs-table-column-group-cell-content {
  display: inline;
  padding: 0;
}

.rs-table-cell-group {
}

.column_table {
  border: 1px dashed gray;
  padding: 0.5rem 1rem;
  cursor: move;
}
.column_header_title {
  float: left;
}
/*.rs-table-cell-content {
  padding: 10px 0;
}*/
.draggable {
  margin-left: 20px;
}

.adm-rs-table-cell-header-summary-wrapper + .rs-table-cell-header-sort-wrapper,
.rs-table-column-group-cell-content + .rs-table-cell-header-sort-wrapper {
  /* position: relative; */
  position: static;
  top: -25px;
}

/*.rs-table-cell-header .rs-table-cell-content {
  padding: 1px 0px;
  font-size: 12px;
  line-height: 1.66666667;
}*/

// .rs-table-hover .rs-table-row:hover  .rs-table-cell{
//  background: blue;
//}
.rs-table-hover .rs-table-row:not(.rs-table-row-header):hover,
.rs-table-hover .rs-table-row:hover .rs-table-cell-group,
.rs-table-hover .rs-table-row:hover .rs-table-cell {
  background: #f2f9ff;
}

.highlight .rs-table-cell {
  background: #68b3f2 !important;
}

.med-variance {
  color: #dddddd !important;
}
.high-variance {
  color: #ff8888 !important;
}
.very-high-variance {
  color: #ff2222 !important;
}

.inner-button-field-group {
  .rs-input[disabled] {
    color: #656667;
  }
}

.inner-button-field-group.rs-input-group:not(.rs-input-group-inside)
  .rs-input-group-btn {
  border-left: 1px solid #d4d4d4;
}

.rev_share_percent-field input {
  text-align: right;
}

.rev_share_from-field a[role="combobox"] {
  border: none;
}
