/* Define styles for the container of the entire table */
/*.table-container {*/
/*  !*width: 1600px; !* Set your desired fixed width *!*!*/
/*  border: 1px solid #ccc; !* Optional border styling *!*/
/*  overflow-x: auto; !* Enable horizontal scrolling *!*/
/*  !*max-height: 500px; !* Set your desired max height *!*!*/
/*}*/

/* Define styles for the grid container */
.grid-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  margin: 10px;
  padding: 10px;
  overflow-x: auto; /* Enable horizontal scrolling */
  width: 1600px; /* Set your desired fixed width */
}

/* Define styles for the grid header */
.grid-header {
  display: grid;
  grid-template-columns: 200px repeat(auto-fill, minmax(200px, 1fr)); /* Equal-width columns */
  background-color: #f0f0f0;
  font-weight: bold;
  position: sticky; /* Make the header sticky */
  top: 0; /* Stick to the top */
  z-index: 2; /* Ensure the header is above the grid body */
}

/* Define styles for grid cells */
.grid-cell {
  border: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  min-height: 40px; /* Adjust as needed for your desired cell height */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Define styles for grid rows */
.grid-row {
  display: flex;
  align-items: center;
  min-height: fit-content;
}

/* Define alternate row background color */
.grid-row:nth-child(even) .grid-cell {
  background-color: #f9f9f9;
}

.grid-body {
  position: relative; /* Make the body relative */
  z-index: 1; /* Ensure the body is below the header */
}

/* Additional styles for grid cells within the grid body */
.grid-body .grid-cell {
  border: 1px solid #ccc;
  padding: 4px;
  text-align: center;
  min-height: 40px; /* Adjust as needed for your desired cell height */
  white-space: normal;
  word-wrap: break-word;
  font-size: 1em;
}

/* Style for the first column including header */
.grid-header .grid-cell.fixed-cell {
  left: 0; /* Stick to the left */
  position: sticky;
  background-color: #f0f0f0; /* Match the header background color */
}

/*!*!* Define styles for grid rows *!*!*/
/*.grid-row {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  min-height: fit-content;*/
/*  !*padding: 5px;*!*/
/*}*/

/*!* Define alternate row background color *!*/
/*.grid-row:nth-child(even) .grid-cell {*/
/*  background-color: #f9f9f9;*/
/*}*/

/*!* Apply background color to header cells *!*/
/*.grid-header .grid-cell {*/
/*  background-color: #f0f0f0;*/
/*}*/

/*.table-container {*/
/*  width: 1600px; !* Set your desired fixed width *!*/
/*  border: 1px solid #ccc; !* Optional border styling *!*/
/*}*/

/*!* Define styles for the grid container *!*/
/*.grid-container {*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  border: 1px solid #ccc;*/
/*  margin: 10px;*/
/*  padding: 10px;*/
/*  overflow-x: auto;*/
/*  position: relative;*/
/*  border-collapse: collapse;*/
/*}*/

/*!* Define styles for the grid header *!*/
/*.grid-header {*/
/*  display: flex;*/
/*  background-color: #f0f0f0;*/
/*  font-weight: bold;*/
/*  !*padding: 5px;*!*/
/*}*/

/*.grid-header.grid-cell {*/
/*  !*position: -webkit-sticky; !* for Safari *!*!*/
/*  position: sticky;*/
/*  top: 0;*/
/*}*/

/*.grid-header.grid-cell:first-child {*/
/*  left: 0;*/
/*  z-index: 1;*/
/*}*/

/*.grid-cell {*/
/*  border: 1px solid #ccc;*/
/*  padding: 4px;*/
/*  text-align: center;*/
/*  min-width: 200px;*/
/*  max-width: 200px; !* Adjust as needed for your desired cell width *!*/
/*  min-height: 40px; !* Adjust as needed for your desired cell height *!*/
/*  white-space: nowrap;*/
/*  overflow: hidden;*/
/*  text-overflow: ellipsis;*/
/*}*/

/*!* Define styles for grid rows *!*/
/*.grid-row {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  min-height: fit-content;*/
/*  !*padding: 5px;*!*/
/*}*/

/*!* Define alternate row background color *!*/
/*.grid-row:nth-child(even) {*/
/*  background-color: #f9f9f9;*/
/*}*/

/*.grid-body .grid-cell {*/
/*  border: 1px solid #ccc;*/
/*  padding: 4px;*/
/*  text-align: center;*/
/*  min-width: 200px;*/
/*  max-width: 200px; !* Adjust as needed for your desired cell width *!*/
/*  min-height: 40px; !* Adjust as needed for your desired cell height *!*/
/*  white-space: normal;*/
/*  word-wrap: break-word;*/
/*  font-size: 1em;*/
/*}*/

/*.grid-container {*/
/*  overflow: scroll;*/
/*  position: relative;*/
/*}*/

/*.grid-header.grid-cell {*/
/*  !*position: -webkit-sticky; !* for Safari *!*!*/
/*  position: sticky;*/
/*  top: 0;*/
/*}*/

/*!*.fixed-cell {*!*/
/*!*  position: absolute;*!*/
/*!*  min-width: 200px;*!*/
/*!*  max-width: 200px; !* Adjust as needed for your desired cell width *!*!*/
/*!*}*!*/
